import { Atom } from ":mods";
import { TOCPageProps } from "./model";

export const ROUTES = {
  TOC: "/terms-and-conditions",
  COMMITMENT: "/commitment",
  SIGN_UP_FOUNDATION: "/",
  CONTACT_INFO: "/contact-info",
  RESIDENCY_INFO: "/residency-info",
  LANGUAGE_INFO: "/language-info",
  EXHIBITION_IDEA: "/exhibition-idea",
  SIGN_UP_TUTORS: "/tutor",
};

export const ENDPOINTS = {
  COMMISSIONS: "/account/commissions",
  COUNTRIES: "/account/countries/",
  STUDENT_ONLINE_SIGNUP: "account/online/registration/list/",
  TUTOR_ONLINE_SIGNUP: "account/tutor/registration/list/",
  GET_AVALIBLE_TUTOR_DATES: "cms/droplist/?list=[TutorAvaliableRegionsDates]",
  GET_TUTOR_TOPIC: "cms/droplist/?list=[TutorTopic]",
  GET_REGIONS: "/account/country/regions/{country}/",
};

export const TUTORS_SIGNUP_STEPS = {
  "talking-topic": 1,
  "contact-info": 2,
  "residency-info": 3,
  "language-info": 4,
  "experience-1": 5,
  "experience-2": 6,
  "talk-info": 7,
};

export const STUDENT_FORM_STEPS: Atom.CircleProgress.CirclePops[] = [
  { step_number: 1, step_name: "Contact Info", completed: false },
  { step_number: 2, step_name: "Residency Info", completed: false },
  { step_number: 3, step_name: "Language Info", completed: false },
  { step_number: 4, step_name: "Exhibition Idea", completed: false },
  { step_number: 5, step_name: "Completed", completed: false, is_last: true },
];

export const TUTORS_FORM_STEPS = [
  { step_number: 1, step_name: "Talking Topic", completed: false },
  { step_number: 2, step_name: "Contact Info", completed: false },
  { step_number: 3, step_name: "Residency Info", completed: false },
  { step_number: 4, step_name: "language Info", completed: false },
  { step_number: 5, step_name: "Experience 1", completed: false },
  { step_number: 6, step_name: "Experience 2", completed: false },
  { step_number: 7, step_name: "Talk Info", completed: false },
  { step_number: 8, step_name: "Completed", completed: false, is_last: true },
];

export const TOC_PAGE: TOCPageProps = {
  title: "Terms & Conditions",
  terms: [
    {
      title: "Lorem ipsum",
      description:
        "dolor sit amet, consectetur adipiscing elit. Mauris eros tellus, laoreet ut massa nec, vestibulum iaculis lectus. Mauris bibendum ultricies lorem non tincidunt. Quisque at semper ipsum. Etiam ultrices augue ut tellus dignissim, hendrerit faucibus purus",
    },
    {
      title: "Lorem ipsum",
      description:
        "dolor sit amet, consectetur adipiscing elit. Mauris eros tellus, laoreet ut massa nec, vestibulum iaculis lectus. Mauris bibendum ultricies lorem non tincidunt. Quisque at semper ipsum. Etiam ultrices augue ut tellus dignissim, hendrerit faucibus purus",
    },
  ],
  agreements: [
    {
      id: 1,
      text: "Nulla condimentum urna dolor, vitae scelerisque arcu interdum",
    },
  ],
};

export const COMMITMENT_PAGE: TOCPageProps = {
  title: "Commitment",
  terms: [
    {
      title: "Lorem ipsum",
      description:
        "dolor sit amet, consectetur adipiscing elit. Mauris eros tellus, laoreet ut massa nec, vestibulum iaculis lectus. Mauris bibendum ultricies lorem non tincidunt. Quisque at semper ipsum. Etiam ultrices augue ut tellus dignissim, hendrerit faucibus purus",
    },
  ],
  agreements: [
    {
      id: 1,
      text: "Nulla condimentum urna dolor, vitae scelerisque arcu interdum",
    },
    {
      id: 2,
      text: "Nulla condimentum urna dolor, vitae scelerisque arcu interdum",
    },
  ],
};
export const TALKING_TOPICS = [
  {
    id: 1,
    name: "Topic Name 1",
    value: "1",
  },
  {
    id: 2,
    name: "Topic Name 2",
    value: "2",
  },
  {
    id: 3,
    name: "Topic Name 3",
    value: "3",
  },
  {
    id: 4,
    name: "Topic Name 4",
    value: "4",
  },
  {
    id: 5,
    name: "Topic Name 5",
    value: "5",
  },
  {
    id: 6,
    name: "Topic Name 6",
    value: "6",
  },
  {
    id: 7,
    name: "Topic Name 7",
    value: "7",
  },
  {
    id: 8,
    name: "Topic Name 8",
    value: "8",
  },
  {
    id: 9,
    name: "Topic Name 9",
    value: "9",
  },
  {
    id: 10,
    name: "Topic Name 10",
    value: "10",
  },
];

export const AVAILABLE_TIME = [
  { id: 1, name: "12:15 - 06/04/2023" },
  { id: 2, name: "12:15 - 06/04/2023" },
  { id: 3, name: "12:15 - 06/04/2023" },
  { id: 4, name: "12:15 - 06/04/2023" },
];
